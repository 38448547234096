<template>
    <div class="investment-suggestions container">
        <div v-for="item in items"
             @click="changeRoute(item.url)"
             class="suggestion-item">
            <div class="background"></div>
            <div class="images-container">
                <picture>
                    <source
                        media="(max-width:767px)"
                        :srcset="item.mobile"
                    />
                    <source
                        media="(max-width:1023px)"
                        :srcset="item.tablet"
                    />
                    <img class="image"
                         :src="item.desktop"
                    />
                </picture>
            </div>
            <div class="content">
                <h2 class="title luxury">{{ item.title }}</h2>
                <div class="explore-container">
                    <svg width="38" height="10" class="icon" viewBox="0 0 38 10" fill="none"
                         xmlns="http://www.w3.org/2000/svg">
                        <path fill-rule="evenodd" clip-rule="evenodd"
                              d="M31.8652 8.75479C31.5964 9.01868 31.5746 9.47032 31.8166
                           9.76354C32.0585 10.0568 32.4726 10.0805 32.7415 9.81663L37.6484 5L32.7415
                            0.183367C32.4726 -0.0805321 32.0585 -0.0567618 31.8166 0.236459C31.5746
                             0.529679 31.5964 0.981315 31.8652 1.24521L35.0279 4.34961H0.65C0.291015
                              4.34961 0 4.64062 0 4.99961C0 5.35859 0.291015 5.64961
                              0.65 5.64961H35.0287L31.8652 8.75479Z"
                              fill="white"></path>
                    </svg>
                    <span class="title fira">{{ item.urlTitle }}</span>
                </div>
            </div>
        </div>

    </div>
</template>

<script setup>
import {computed} from "vue";
import {useRouter} from "vue-router";

const props = defineProps({
    contentData: {
        type: Object,
    },
});

let router = useRouter()
let title = computed(() => props.contentData?.conf?.header?.title)
let items = computed(() => {

    return props.contentData.data?.list?.map(item => ({
        ...item,
        mobile: item?.images?.[0]?.devices?.mobile,
        desktop: item?.images?.[0]?.devices?.desktop,
        tablet: item?.images?.[0]?.devices?.tablet,
        urlTitle: item.url?.title,
        url: item.url?.url,
    }))
})
const changeRoute = (url) => {
    router.push({
        path: url,
    })
}

</script>

<style lang="scss" scoped>
$ease: cubic-bezier(.11, 0, .21, .98);
.investment-suggestions {
    margin-top: 144px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: white;

    @media only screen and (max-width: 767px) {
        flex-direction: column;
        margin-top: 89px;
    }

    .suggestion-item {
        width: calc(50% - 10px);
        height: 610px;
        position: relative;
        cursor: pointer;
        @media only screen and (max-width: 1650px) and (min-width: 1440px) {
            height: 500px;
        }
        @media only screen and (max-width: 1439px) and (min-width: 1024px) {
            height: 450px;
        }
        @media only screen and (max-width: 1023px) and (min-width: 768px) {
            height: 400px;
        }
        @media only screen and (max-width: 767px) {
            height: 300px;
            width: 100%;
            &:last-child {
                margin-top: 21px;
            }
        }

        &:hover {
            .image {
                transform: scale(1.1);
            }

            .explore-container {
                svg {
                    opacity: .6;
                    animation: goBack .45s cubic-bezier(.23,-0.01,.03,1);
                }

                .title {
                    opacity: .6;
                }
            }
        }

        .content {
            position: absolute;
            left: 5%;
            bottom: 70px;
            z-index: 2;

            .title {
                font-weight: 400;
                font-size: 55px;
                text-transform: uppercase;
                @media only screen and (max-width: 1439px) {
                    font-size: 34px;
                }
            }
        }

        .images-container {
            position: relative;
            overflow: hidden;
            width: 100%;
            height: 100%;

            .picture {
                width: 100%;
                height: 100%;
            }

            .image {
                width: 100%;
                height: 100%;
                object-fit: cover;
                transition: all .45s ease-out;
            }

        }

        .explore-container {
            display: flex;
            align-items: center;
            margin-top: 15px;

            .icon {
                margin-right: 15px;
                width: 25px;
                transition: all .45s cubic-bezier(.23,-0.01,.03,1);
            }

            .title {
                font-weight: 700;
                font-size: 13px;
                letter-spacing: 0.1em;
                text-transform: uppercase;
            }
        }

        .background {
            position: absolute;
            left: 0;
            bottom: 0;
            width: 100%;
            height: 35%;
            background: linear-gradient(180deg, #182236 0%, rgba(24, 34, 54, 0) 100%);
            z-index: 1;
            transform: rotate(180deg);
        }
    }

    @keyframes goBack {
        0% {
            transform: translateX(0);
        }
        50% {
            transform: translateX(5px);
        }
        100% {
            transform: translateX(0);
        }
    }

}
</style>

